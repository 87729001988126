import React, { ReactNode } from 'react';

import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';

import { ToolTip } from 'components/UI/atoms/tooltip/ToolTip';

import styles from './Label.module.less';

export interface ILabelProps {
    name: string;
    variant:
        | 'Green'
        | 'Violet'
        | 'Yellow'
        | 'Blue'
        | 'Red'
        | 'Neutral'
        | 'Pink';
    tooltipText?: ReactNode;
    placement?: TooltipPlacement;
    className?: string;
    isMobileReducedWidth?: boolean;
}

export const Label = ({
    name,
    variant,
    tooltipText,
    placement = 'bottom',
    className,
    isMobileReducedWidth = false
}: ILabelProps) => {
    return tooltipText ? (
        <ToolTip
            title={tooltipText}
            placement={placement}
            mobileReducedWidth={isMobileReducedWidth}
            regularDisplayMode={true}
        >
            <div
                className={classNames(
                    styles.Label,
                    styles[`Label__${variant}`],
                    styles.Label__Tooltip,
                    className
                )}
                id="TooltipLabelName"
                data-testid="tooltip-name"
            >
                {name}
            </div>
        </ToolTip>
    ) : (
        <div
            className={classNames(
                styles.Label,
                styles[`Label__${variant}`],
                className
            )}
            id="LabelName"
            data-testid="label-name"
        >
            {name}
        </div>
    );
};
