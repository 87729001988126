import React, { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import { useGetOrdersMutation } from 'APIServices/trackOrders/TrackOrders.api';
import { GetOrdersDTO } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { SvgArrowFullRight, SvgInfo } from 'components/icons';
import { Button } from 'components/UI/atoms/button/Button';
import { Refresh } from 'components/UI/atoms/refresh/Refresh';
import { ToolTip } from 'components/UI/atoms/tooltip/ToolTip';
import { HomeBlock } from 'components/UI/organisms/home/shared/homeBlock/HomeBlock';
import { WatchListItems } from 'components/UI/organisms/home/watchList/watchListItems/WatchListItems';
import { WatchListItemsMobile } from 'components/UI/organisms/home/watchList/watchListItemsMobile/WatchListItemsMobile';
import {
    getDateFilterData,
    getFilters
} from 'components/UI/organisms/trackOrders/orderList/ordersListUrlUtils';
import { OrderWatchListMessage } from 'utils/constants/messages';
import { RoutesList } from 'utils/constants/RoutesList';
import { PROGRESS_STATUSES } from 'utils/helpers/orderStatus';
import { useLastUpdate } from 'utils/hooks/useLastUpdate';

import styles from './WatchList.module.less';

interface Props {
    isMobileVersion: boolean;
    isDarkModeEnabled?: boolean;
}

export const WatchList = ({ isMobileVersion, isDarkModeEnabled }: Props) => {
    const [getOrders, options] = useGetOrdersMutation();
    const navigate = useNavigate();
    const { setLastUpdated, fromLastRefresh } = useLastUpdate();
    const { isLoading, isError } = options;

    const items = options?.data?.data?.items;

    const currentDate = DateTime.now();
    const watchlistDateFrom = currentDate.minus({ year: 1 }).toISODate();
    const watchlistDateTo = currentDate.toISODate();
    const watchlistDateType = 'CreateDate';

    const ordersConfig: GetOrdersDTO = {
        filters: getFilters({
            showWatchList: 'true',
            status: Object.values(PROGRESS_STATUSES).join('|'),
            date: getDateFilterData({
                dateFrom: watchlistDateFrom,
                dateTo: watchlistDateTo,
                dateType: watchlistDateType
            }).filterData
        }),
        sorts: '-WatchListAddDate',
        page: 1,
        pageSize: 3
    };

    useEffect(() => {
        getOrders(ordersConfig);
    }, []);

    const fetchWatchlistData = () => {
        getOrders(ordersConfig);

        setLastUpdated(Date.now());
    };

    const onRefreshClick = () => {
        navigate(RoutesList.ROOT);
    };

    const showAllDisabled = !items?.length;

    if (isError) {
        return (
            <div className={styles.ErrorSection} data-testid="error-section">
                <span
                    className={styles.ErrorSection__Title}
                    data-testid="error-section-title"
                >
                    Oops, something went wrong
                </span>
                <span
                    className={styles.ErrorSection__Subtitle}
                    data-testid="error-section-sub-title"
                >
                    You may refresh the page or try again later
                </span>
                <div className={styles.ErrorSection__RefreshButtonSection}>
                    <Button
                        theme="primary"
                        text={'Refresh'}
                        onClick={onRefreshClick}
                        data-testid="pick-up-date-tooltip"
                    />
                </div>
            </div>
        );
    }

    const goToAllWatchList = () => {
        const path = RoutesList.TRACK_ORDERS.ROOT;

        navigate(`/${path}`, { state: { redirectFromWatchList: true } });
    };

    const darkModeArrowSetting = isDarkModeEnabled ? '#b2c0f2' : '#273C83';

    return (
        <HomeBlock
            title="Order Watchlist"
            data-testid="home-block"
            watchListHeaderSetting={isMobileVersion}
            actionContent={
                <div className={styles.ActionsContainer}>
                    <div>
                        <Refresh
                            onRefreshClick={() => {
                                fetchWatchlistData();
                            }}
                            lastUpdated={fromLastRefresh}
                            data-testid="refresh-button"
                            reverse={!isMobileVersion}
                            isMobile={isMobileVersion}
                            watchListMode={true}
                            isDarkModeEnabled={isDarkModeEnabled}
                        />
                    </div>
                    <div className={styles.ActionsContainer__ShowAllItems}>
                        <div
                            onClick={
                                !showAllDisabled ? goToAllWatchList : undefined
                            }
                        >
                            Show All Watchlist
                        </div>
                        <SvgArrowFullRight
                            color={
                                showAllDisabled
                                    ? '#8B91A7'
                                    : `${darkModeArrowSetting}`
                            }
                        />
                    </div>
                </div>
            }
            actionDisabled={showAllDisabled}
            additionalHeaderContent={
                <div className={styles.ActionAdditionalContent}>
                    <div
                        className={
                            styles.ActionAdditionalContent__WatchlistTooltip
                        }
                    >
                        <ToolTip
                            title={OrderWatchListMessage}
                            placement="bottom"
                            data-testid="watchlist-tooltip"
                            regularDisplayMode={true}
                        >
                            <SvgInfo data-testid="watchlist-tooltip-icon" />
                        </ToolTip>
                    </div>
                </div>
            }
        >
            <div className={styles.Content} data-testid="content-section">
                {isMobileVersion ? (
                    <WatchListItemsMobile
                        watchlistItems={items}
                        isLoading={isLoading}
                        isMobileVersion={isMobileVersion}
                        isDarkModeEnabled={isDarkModeEnabled}
                    />
                ) : (
                    <WatchListItems
                        watchlistItems={items}
                        isLoading={isLoading}
                        isDarkModeEnabled={isDarkModeEnabled}
                    />
                )}
            </div>
        </HomeBlock>
    );
};
