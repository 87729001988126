import {
    AccountSubAccount,
    GetBasicDtoPagination,
    RequestStatusWithData
} from 'utils/types/api/api.types';
import {
    AccountWarehouse,
    BaseWarehouse
} from 'utils/types/general/warehouse.type';

export interface GetAvailableWarehouseDTO extends AccountSubAccount {
    consigneeCountryCode: string;
    consigneePostalCode?: string;
    consigneeCity?: string;
    consigneeProvince?: string;
    parts: {
        clientItemId: string;
        includeSubs?: boolean;
        requiredQty: number;
    }[];
}

export interface GetIOHPartsDTO extends GetBasicDtoPagination {
    readonly consignorSsl: string;
}

export interface WarehousePart {
    clientItemId: string;
    availableQty: number;
    clientSubstitutes: string[];
    validatedWithSubstitutes: boolean;
}

export type GetAccountWarehouseDTO = AccountSubAccount;

export type GetAvailableWarehouseTransformedResponse = RequestStatusWithData<{
    items: AccountWarehouse[];
}>;

export type GetWarehouseResponse = RequestStatusWithData<{
    available: BaseWarehouse[];
    unavailable: BaseWarehouse[];
}>;

export type GetWarehouseTransformedResponse = RequestStatusWithData<{
    available: AccountWarehouse[];
    unavailable: AccountWarehouse[];
}>;

export enum REQUEST_PATH {
    AVAILABLE_WAREHOUSE = '/CustomerDelivery/GoodParts/AvailableSslInventory',
    INVENTORY = '/ReturnParts/GoodFromChoice/SslInventory'
}
