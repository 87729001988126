import React from 'react';

import { Tooltip, TooltipProps } from 'antd';
import classNames from 'classnames';

import { isMobileAndTablet } from 'utils/constants/breakpoints';
import { tooltipTrigger } from 'utils/constants/tooltipTrigger';
import { useWindowDimentions } from 'utils/hooks/useWindowDimentions';

import styles from './Tooltip.module.less';

type TooltipExtendedProps = TooltipProps & {
    mobileReducedWidth?: boolean;
    renderToThePortal?: boolean;
    regularDisplayMode?: boolean;
};

export const ToolTip = ({
    title,
    color = 'var(--tooltipBackground)',
    placement,
    visible,
    children,
    className,
    overlayClassName,
    mobileReducedWidth,
    renderToThePortal = false,
    regularDisplayMode = false,
    ...props
}: TooltipExtendedProps) => {
    const { width } = useWindowDimentions();
    const isMobileVersion = isMobileAndTablet(width);

    const tooltip = (
        <Tooltip
            trigger={tooltipTrigger}
            overlayClassName={classNames(styles.Tooltip, overlayClassName, {
                [styles.Tooltip__ReducedWidth]: mobileReducedWidth
            })}
            title={title}
            color={color}
            placement={placement}
            arrowPointAtCenter={true}
            visible={visible}
            data-testid="ToolTipContainer"
            getPopupContainer={(triggerNode) =>
                renderToThePortal
                    ? document.body
                    : triggerNode.parentElement || document.body
            }
            overlayInnerStyle={{ hyphens: 'auto', whiteSpace: 'normal' }}
            className={className}
            {...props}
        >
            {children}
        </Tooltip>
    );

    return isMobileVersion && !regularDisplayMode ? <>{children}</> : tooltip;
};
