import React from 'react';

import classNames from 'classnames';

import { Button } from 'components/UI/atoms/button/Button';
import { ILabelProps } from 'components/UI/atoms/label/Label';
import { OrderStatusButtonMobile } from 'components/UI/organisms/mirAndTracking/orderStatusButtonMobile/OrderStatusButtonMobile';
import styles from 'components/UI/organisms/mirAndTracking/orderStatusLabel/OrderStatusLabel.module.less';
import { MIR_STATUSES, PROGRESS_STATUSES } from 'utils/helpers/orderStatus';

interface IProps {
    status?: string;
    count: number;
    variant?: ILabelProps['variant'];
    disabled?: boolean;
    selected?: boolean;
    isMobile: boolean;
    onStatusClick?: (status: PROGRESS_STATUSES | MIR_STATUSES) => void;
}

export const OrderStatusLabel = ({
    status,
    count,
    variant = 'Blue',
    disabled,
    selected,
    isMobile = false,
    onStatusClick
}: IProps) => {
    const isFilterDisabled = disabled || count === 0;
    const buttonClasses = classNames(
        styles.Count,
        styles[`Count__${variant}`],
        {
            [styles.Count__Selected]: selected,
            [styles.Count__Disabled]: isFilterDisabled
        }
    );

    const containerDataId = isMobile
        ? 'OrderStatusLabelMobile'
        : 'OrderStatusLabel';

    const statusDataId = isMobile
        ? 'OrderStatusLabelNameMobile'
        : 'OrderStatusLabelName';

    return status ? (
        <div
            className={styles.Container}
            data-testid={`${containerDataId}_${status}`}
        >
            {!isMobile && (
                <div
                    className={classNames(styles.Status, {
                        [styles.Status__Total]: !status
                    })}
                    data-testid={`${statusDataId}_${status}`}
                >
                    {status}:
                </div>
            )}

            {isMobile ? (
                <OrderStatusButtonMobile
                    text={count ? count.toString() : '0'}
                    status={status}
                    disabled={isFilterDisabled}
                    onClick={() =>
                        onStatusClick?.(
                            status as PROGRESS_STATUSES | MIR_STATUSES
                        )
                    }
                    className={buttonClasses}
                    testId={'OrderStatusLabelMobileButton'}
                />
            ) : (
                <Button
                    theme="tertiary"
                    text={count ? count.toString() : '0'}
                    disabled={isFilterDisabled}
                    onClick={() =>
                        onStatusClick?.(
                            status as PROGRESS_STATUSES | MIR_STATUSES
                        )
                    }
                    className={buttonClasses}
                    testId={`OrderStatusLabelButton_${status}`}
                />
            )}
        </div>
    ) : null;
};
