import React, { lazy, ReactNode, Suspense, useEffect, useState } from 'react';

import { TreeSelect as AntTreeSelect } from 'antd';

import { GetOrdersDTO } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { SvgSliders } from 'components/icons';
import { Loader } from 'components/UI/atoms/loader/Loader';
import { OrderStatusLabelsBar } from 'components/UI/organisms/mirAndTracking/orderStatusLabelsBar/OrderStatusLabelsBar';
import { SearchAndFilters } from 'components/UI/organisms/mirAndTracking/SearchAndFilters';
import { AccountFilter } from 'components/UI/organisms/trackOrders/orderList/components/accountFilter/AccountFilter';
import { DateRangeFilter } from 'components/UI/organisms/trackOrders/orderList/components/dateRangeFilter/DateRangeFilter';
import { OrderTypeFilter } from 'components/UI/organisms/trackOrders/orderList/components/orderTypeFilter/OrderTypeFilter';
import { SelectedFilterValues } from 'components/UI/organisms/trackOrders/orderList/components/selectedFilterValues/SelectedFilterValues';
import { checkIfDefaultPeriodSelected } from 'components/UI/organisms/trackOrders/orderList/ordersListUrlUtils';
import { isMobileAndTablet } from 'utils/constants/breakpoints';
import { PROGRESS_STATUSES } from 'utils/helpers/orderStatus';
import { sortOrderTypesList } from 'utils/helpers/sorting';
import { useOrdersListFilters } from 'utils/hooks/useOrdersListFilters';
import { Status } from 'utils/types/general/general.types';
import { FilterItem } from 'utils/types/mirAndTracking/MirAndTracking.types';

const AllFiltersWrapper = lazy(
    () => import('components/UI/organisms/allFiltersWrapper/AllFiltersWrapper')
);
const AllOrderFiltersMobile = lazy(
    () =>
        import(
            'components/UI/organisms/trackOrders/orderList/components/allOrderFiltersMobile/AllOrderFiltersMobile'
        )
);
const AllOrderFilters = lazy(
    () =>
        import(
            'components/UI/organisms/trackOrders/orderList/components/allOrderFilters/AllOrderFilters'
        )
);

const { SHOW_PARENT } = AntTreeSelect;

interface IProps {
    children: ReactNode | ReactNode[];
    placeholder: string;
    styles: {
        [key: string]: string;
    };
    getData: (config: GetOrdersDTO) => void;
    isLoading: boolean;
    statuses?: Status[];
    width: number;
    orderCacheId: string | undefined;
}

export const OrdersListWrapper = ({
    placeholder,
    children,
    styles,
    getData,
    statuses,
    isLoading,
    width,
    orderCacheId
}: IProps) => {
    const {
        searchQuery,
        searchInputValue,
        onChangeSearch,
        onSubmitSearch,
        onSearchInputClean,
        lastUpdated,
        onRefreshClick,
        applyOrderTypes,
        onRemoveOrderType,
        orderTypes,
        partConditionTypesList,
        deliveryTypesList,
        selectedFilters,
        clearAllSelectedFilters,
        dateFilterData,
        minAvailableDateRange,
        onChangeDates,
        onResetPeriod,
        selectedDatesLabel,
        selectedDates,
        selectedOrderTypes,
        selectedPartConditionTypes,
        onRemovePartConditionParam,
        selectedDeliveryTypes,
        onRemoveDeliveryParam,
        accounts,
        accountsAndSubs,
        originLocations,
        selectedOriginLocations,
        destinationLocations,
        selectedDestinationLocations,
        hasSelectedAccounts,
        hasSelectedOriginLocations,
        hasSelectedDestinationLocations,
        selectedAccountsAndSubs,
        transportTypes,
        onRemoveAccountsAndSubs,
        applyAccountsAndSubAccounts,
        onStatusSelect,
        showDangerousGoods: dangerousGoodsSelectedState,
        warehousesData,
        onRemoveDangerousGoodsParam,
        onRemoveOriginLocationsParam,
        onRemoveDestinationLocationsParam,
        status,
        showWatchList: watchListSelectedState,
        onRemoveWatchListParam,
        hasSelectedTransportTypes,
        onRemoveTransportType,
        selectedTransportTypes,
        allTransportTypes
    } = useOrdersListFilters(getData, orderCacheId);

    const hasSearchQuery = Boolean(searchQuery);

    const [isAllFiltersPanelOpen, setIsAllFiltersPanelOpen] = useState(false);

    const toggleAllFiltersPanel = () => {
        setIsAllFiltersPanelOpen((prevState) => !prevState);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        const targetItems = [
            '.ant-layout-sider',
            '.ant-popover',
            '.ant-select-tree-checkbox',
            '.ant-select-tree-node-content-wrapper',
            '.ant-collapse-header',
            '.ant-collapse-expand-icon',
            '.ant-collapse-arrow',
            '.react-datepicker__day',
            '#AllFilters',
            '#accountsAndSubs',
            '#orderTypes',
            '#originLocations',
            '#destinationLocations',
            '#partConditionTypes',
            '#deliveryTypes',
            '#prevYear',
            '#nextYear',
            '#prevMonth',
            '#nextMonth'
        ];

        const isShouldBeClosed = targetItems.every(
            (item) => !target?.closest(item)
        );

        if (isShouldBeClosed) {
            setIsAllFiltersPanelOpen(false);
        }
    };

    const filterSettings: FilterItem[] = [
        {
            isVisible: !isMobileAndTablet(width),
            filterComponent: (
                <DateRangeFilter
                    defaultDateRange={dateFilterData.defaultDateRangeDays}
                    minDate={minAvailableDateRange}
                    from={dateFilterData.from}
                    to={dateFilterData.to}
                    onChangeDates={onChangeDates}
                    onResetPeriod={onResetPeriod}
                    dateType={dateFilterData.dateType}
                    disabled={hasSearchQuery}
                />
            )
        },
        {
            isVisible: !isMobileAndTablet(width),
            filterComponent: (
                <AccountFilter
                    selectedFilterItems={accountsAndSubs}
                    onApplyAccounts={applyAccountsAndSubAccounts}
                    disabled={hasSearchQuery}
                    filterDataSetting={'accountsAndSubs'}
                    showCheckedStrategy={SHOW_PARENT}
                />
            )
        },
        {
            isVisible: !isMobileAndTablet(width),
            filterComponent: (
                <OrderTypeFilter
                    onApplyOrderTypes={applyOrderTypes}
                    selectedOrderTypes={selectedOrderTypes}
                    orderTypesList={
                        orderTypes && sortOrderTypesList(orderTypes)
                    }
                    disabled={hasSearchQuery}
                />
            )
        },
        {
            filterName: 'All Filters',
            isVisible: true,
            icon: <SvgSliders />,
            isSelected: selectedFilters,
            onClick: () => toggleAllFiltersPanel(),
            withArrow: true,
            customId: 'AllFilters'
        }
    ];

    const showDatesLabel = !checkIfDefaultPeriodSelected({
        defaultDateRangeDays: dateFilterData.defaultDateRangeDays,
        from: dateFilterData.from,
        to: dateFilterData.to,
        dateType: dateFilterData.dateType
    });

    const selectedFiltersData = [
        {
            onRemove: onResetPeriod,
            label: selectedDatesLabel,
            values: selectedDates,
            isSelected: showDatesLabel
        },
        {
            onRemove: onRemoveAccountsAndSubs,
            label: 'Account',
            values: selectedAccountsAndSubs,
            isSelected: hasSelectedAccounts
        },
        {
            onRemove: onRemoveTransportType,
            label: 'Transport Types',
            values: selectedTransportTypes,
            isSelected: hasSelectedTransportTypes
        },
        {
            onRemove: onRemoveOrderType,
            label: 'Order Type',
            values: selectedOrderTypes || [],
            isSelected: Boolean(selectedOrderTypes?.length)
        },
        {
            onRemove: onRemoveDangerousGoodsParam,
            values: ['Dangerous Good orders only'],
            isSelected: Boolean(
                dangerousGoodsSelectedState &&
                    dangerousGoodsSelectedState !== 'false'
            )
        },
        {
            onRemove: onRemoveWatchListParam,
            values: ['Watchlist orders only'],
            isSelected: Boolean(
                watchListSelectedState && watchListSelectedState !== 'false'
            )
        },
        {
            onRemove: onRemoveOriginLocationsParam,
            label: 'Origin',
            values: selectedOriginLocations,
            isSelected: Boolean(
                hasSelectedOriginLocations && selectedOriginLocations
            )
        },
        {
            onRemove: onRemoveDestinationLocationsParam,
            label: 'Destination',
            values: selectedDestinationLocations,
            isSelected: Boolean(
                hasSelectedDestinationLocations && selectedDestinationLocations
            )
        },
        {
            onRemove: onRemovePartConditionParam,
            label: 'Part Condition',
            values: selectedPartConditionTypes || [],
            isSelected: Boolean(
                selectedPartConditionTypes && selectedPartConditionTypes?.length
            )
        },
        {
            onRemove: onRemoveDeliveryParam,
            label: 'Domestic/International',
            values: selectedDeliveryTypes || [],
            isSelected: Boolean(
                selectedDeliveryTypes && selectedDeliveryTypes?.length
            )
        }
    ];

    return (
        <SearchAndFilters
            searchQuery={searchQuery}
            searchInputValue={searchInputValue}
            styles={styles}
            isTracking={true}
            onChangeSearch={onChangeSearch}
            onRefreshClick={onRefreshClick}
            filterSettings={filterSettings}
            searchPlaceholder={placeholder}
            onSubmitSearch={onSubmitSearch}
            title="Track Orders"
            onSearchInputClean={onSearchInputClean}
            isMobile={isMobileAndTablet(width)}
            lastUpdated={lastUpdated}
            isAllFiltersPanelOpen={isAllFiltersPanelOpen}
            toggleAllFiltersPanel={toggleAllFiltersPanel}
        >
            {selectedFilters && !hasSearchQuery && (
                <div
                    className={styles.Filters__Selected}
                    data-testid="SelectedFilters"
                >
                    <SelectedFilterValues
                        selectedFiltersData={selectedFiltersData}
                        clearAllSelectedFilters={clearAllSelectedFilters}
                        clearAllDesktop={true}
                    />
                </div>
            )}

            {!isLoading && (
                <OrderStatusLabelsBar
                    statuses={statuses || []}
                    lastUpdated={lastUpdated}
                    onRefreshClick={onRefreshClick}
                    isMobile={false}
                    onStatusSelect={(value) =>
                        onStatusSelect(value as PROGRESS_STATUSES)
                    }
                    selectedStatus={status as PROGRESS_STATUSES}
                />
            )}

            <Suspense
                fallback={
                    <div className={styles.AllFiltersSiderPlaceholder}>
                        <Loader fullHeight />
                    </div>
                }
            >
                {isAllFiltersPanelOpen && (
                    <AllFiltersWrapper
                        isAllFiltersPanelOpen={isAllFiltersPanelOpen}
                        toggleAllFiltersPanel={toggleAllFiltersPanel}
                        getData={getData}
                        selectedStatus={status as PROGRESS_STATUSES}
                        from={dateFilterData?.from}
                        to={dateFilterData?.to}
                        defaultFrom={dateFilterData?.defaultFrom}
                        defaultTo={dateFilterData?.defaultTo}
                        selectedAccountsAndSubs={accountsAndSubs}
                        selectedTransports={transportTypes}
                        selectedOriginLocations={originLocations}
                        selectedDestinationLocations={destinationLocations}
                        rangeType={dateFilterData.dateType}
                        selectedOrderTypes={selectedOrderTypes}
                        selectedPartConditionTypes={selectedPartConditionTypes}
                        selectedDeliveryTypes={selectedDeliveryTypes}
                        warehouses={warehousesData?.data}
                    >
                        {isMobileAndTablet(width) ? (
                            <AllOrderFiltersMobile
                                isLoading={isLoading}
                                accounts={accounts}
                                minAvailableDateRange={minAvailableDateRange}
                                defaultDateRange={
                                    dateFilterData.defaultDateRangeDays
                                }
                                defaultRangeType={dateFilterData.dateType}
                                statuses={statuses || []}
                                status={status}
                                lastUpdated={lastUpdated}
                                onRefreshClick={onRefreshClick}
                                onStatusSelect={onStatusSelect}
                                selectedStatus={status as PROGRESS_STATUSES}
                                hasSearchValue={hasSearchQuery}
                                selectedFilters={selectedFilters}
                                dateFilterData={dateFilterData}
                                onResetPeriod={onResetPeriod}
                                selectedOrderTypes={selectedOrderTypes}
                                selectedPartConditionTypes={
                                    selectedPartConditionTypes
                                }
                                selectedDeliveryTypes={selectedDeliveryTypes}
                                orderTypes={orderTypes}
                                partConditionTypes={partConditionTypesList}
                                deliveryTypes={deliveryTypesList}
                                isAllFiltersPanelOpen={isAllFiltersPanelOpen}
                                dangerousGoodsSelectedState={
                                    dangerousGoodsSelectedState
                                }
                                watchListSelectedState={watchListSelectedState}
                                clearAllSelectedFilters={
                                    clearAllSelectedFilters
                                }
                                toggleAllFiltersPanel={toggleAllFiltersPanel}
                                allTransportTypes={allTransportTypes?.data}
                            />
                        ) : (
                            <AllOrderFilters
                                isLoading={isLoading}
                                minAvailableDateRange={minAvailableDateRange}
                                defaultDateRange={
                                    dateFilterData.defaultDateRangeDays
                                }
                                defaultRangeType={dateFilterData.dateType}
                                selectedFilters={selectedFilters}
                                dateFilterData={dateFilterData}
                                onResetPeriod={onResetPeriod}
                                selectedOrderTypes={selectedOrderTypes}
                                selectedPartConditionTypes={
                                    selectedPartConditionTypes
                                }
                                selectedDeliveryTypes={selectedDeliveryTypes}
                                orderTypes={orderTypes}
                                partConditionTypes={partConditionTypesList}
                                deliveryTypes={deliveryTypesList}
                                allTransportTypes={allTransportTypes}
                                isAllFiltersPanelOpen={isAllFiltersPanelOpen}
                                dangerousGoodsSelectedState={
                                    dangerousGoodsSelectedState
                                }
                                watchListSelectedState={watchListSelectedState}
                                clearAllSelectedFilters={
                                    clearAllSelectedFilters
                                }
                                toggleAllFiltersPanel={toggleAllFiltersPanel}
                            />
                        )}
                    </AllFiltersWrapper>
                )}
            </Suspense>

            <div data-testid="TableBlock" className={styles.TableBlock}>
                {children}
            </div>
        </SearchAndFilters>
    );
};
