import React, { ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from 'components/UI/molecules/quickAccessButton/QuickAccessButton.module.less';

interface Props {
    icon: ReactNode;
    title: string;
    label: string;
    redirectTo?: string;
}
export const QuickAccessButton = ({
    title,
    label,
    icon,
    redirectTo
}: Props) => {
    const navigation = useNavigate();

    const goToPage = () => {
        if (redirectTo) {
            navigation(`/${redirectTo}`);
        }
    };

    return (
        <div className={styles.Container} onClick={goToPage}>
            <div className={styles.Container__Icon}>{icon}</div>
            <div className={styles.Container__Text}>
                <span className={styles.Title}>{title}</span>
                <span className={styles.Label}>{label}</span>
            </div>
        </div>
    );
};
