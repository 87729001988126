import {
    useGetMaterialsReferenceNumberLabelsMutation,
    useSubmitInboundReplenishmentMutation,
    useGetPartsMaterialsMutation
} from 'APIServices/inboundReplenishment/InboundReplenishment.api';
import {
    useGetWarehousePartsMutation,
    usePreCheckTransferByWarehouseMutation
} from 'APIServices/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import {
    usePreCheckByPartMutation,
    useGetOutboundOrderPartsMutation,
    useGetSerialNumbersMutation,
    useSubmitFullOrderByPartMutation,
    useSubmitReducedOrderMutation
} from 'APIServices/outboundOrderByPart/OutboundOrderByPart.api';
import {
    useGetAccountWarehouseMutation,
    useGetServicesMutation,
    useGetOrderReferenceNumberLabelsMutation,
    usePreCheckByWarehouseMutation,
    useSubmitFullOrderByWarehouseMutation,
    useDefectiveGetWarehousePartsMutation
} from 'APIServices/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import {
    VoidFunction,
    InitialReducerState,
    Pagination,
    Sorting
} from 'utils/types/general/general.types';

export interface IError {
    code: number;
    message: string;
}

export enum STATUS_CODES {
    SUCCESS = 0,
    BASE_ERROR = 101,
    INVALID_DESTINATION = 21,
    DATA_NOT_AVAILABLE = 100,
    HTTP_ERROR = 400,
    INTERNAL_ERROR = 1,
    DATA_NOT_FOUND = 4,
    INVALID_CHOICE_ERROR = 7,
    FILTERED_DATA_NOT_FOUND = 3,
    SERVER_ERROR = 500,

    // AUTH
    AUTH_EXPIRED = 101
}

export const unexpectedErrorsList = [500, 7, 1];

export enum HTTP_METHODS {
    POST = 'post',
    GET = 'get',
    PUT = 'put'
}

interface RequestStatus {
    statusCode: number;
    statusMessage: string;
}

export interface RequestStatusWithData<T> extends RequestStatus {
    data: T;
}

export type IRequestStatusWithEmptyData = RequestStatusWithData<
    Record<string, never>
>;

export interface RequestWithPagination {
    readonly filter: string;
    readonly sorts: Sorting;
    readonly pagination: Pagination;
}

export interface MasterInitialState extends InitialReducerState {
    pagination: Pagination;
}

export interface GetBasicDtoPagination extends RequestWithPagination {
    readonly accountNumber?: string;
    readonly subAccountNumber?: string;
}

export interface AccountSubAccount {
    accountNum?: string;
    subAccountNum?: string;
}

export interface BasicPaginationResponse {
    error: IError;
    pagesCount: number;
    totalItemsCount: number;
}

export type UseMutationResult<T> = {
    // Base query state
    originalArgs?: unknown;
    // Arguments passed to the latest mutation call. Not available if using the `fixedCacheKey` option
    data?: T; // Returned result if present
    error?: unknown; // Error result if present
    endpointName?: string; // The name of the given endpoint for the mutation
    fulfilledTimestamp?: number; // Timestamp for when the mutation was completed

    // Derived request status booleans
    isUninitialized: boolean; // Mutation has not been fired yet
    isLoading: boolean; // Mutation has been fired and is awaiting a response
    isSuccess: boolean; // Mutation has data from a successful call
    isError: boolean; // Mutation is currently in an "error" state
    startedTimeStamp?: number; // Timestamp for when the latest mutation was initiated

    reset: VoidFunction;
    // A method to manually unsubscribe from the mutation call and reset the result to the uninitialized state
};

export interface ConsigneeConsignorAddress {
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    province?: string;
    postalCode?: string;
    contact?: string;
    phone?: string;
    countryCode: string;
    contactEmail?: string | null;
    phoneCountryCode?: string | null;
    phoneExt?: string | null;
}

export interface SubmitPartReference {
    requiredQty: number | null;
    partReferenceNumber: string | null;
    clientSerials?: string[];
}

export interface SubmitPartDTOItem {
    clientItemId: string;
    partReferences: SubmitPartReference[] | null;
    requiredQty: number;
}

export interface BasePreCheckDTO extends AccountSubAccount {
    consignorSsl?: string;
    parts: SubmitPartDTOItem[];
}

export interface PreCheckDTO extends BasePreCheckDTO {
    filters?: string;
    sorts?: string;
    page?: number;
    pageSize?: number;
}

export interface PreCheckItem {
    isValid: boolean;
    clientItemId: string;
    requiredQty: number;
    availableQty: number;
    partReferenceNumber: string | null;
    clientSerial: string | null;
}

interface PreCheckData extends BasicPaginationResponse {
    isPreCheckSuccessful: boolean;
    items: PreCheckItem[];
    totalAvailableQty: number;
}

export type PreCheckResponse = RequestStatusWithData<PreCheckData>;

export type PreCheckRow = Omit<PreCheckItem, 'isValid'>;

export interface PreCheck extends PreCheckRow {
    id: string;
}

export interface SubmitOrderDTO extends BasePreCheckDTO {
    consigneeAddress?: Partial<ConsigneeConsignorAddress>;
    consignorAddress?: Partial<ConsigneeConsignorAddress>;
    consigneeSsl?: string;
    serviceLevel: string;
    isEarliestReady?: boolean;
    transportationType?: string | null;
    requestedDeliveryDateUTC?: string | null;
    referenceNumber: string;
    referenceNumber2?: string;
    referenceNumber3?: string;
    referenceNumber4?: string;
    isIncludeLabelsForReturnPackages?: boolean;
    notes?: string[] | null;
    inboundExpDeliveryDate?: string | null;
    airWayBill?: string | null;
}

export interface SubmitReducedOrder {
    submittedParts: SubmitPartDTOItem[];
}

export type SubmitFullOrderResponse = IRequestStatusWithEmptyData;
export type SubmitReducedOrderResponse =
    RequestStatusWithData<SubmitReducedOrder>;

export type SubmitOrder = SubmitFullOrderResponse | SubmitReducedOrderResponse;

export interface WarehouseRequestsConfig {
    useGetAccountWarehouseMutation: typeof useGetAccountWarehouseMutation;
    useGetServicesMutation: typeof useGetServicesMutation;
    useGetWarehousePartsMutation: typeof useDefectiveGetWarehousePartsMutation;
    useGetOrderReferenceNumberLabelsMutation: typeof useGetOrderReferenceNumberLabelsMutation;
    useGetSerialNumbersMutation: typeof useGetSerialNumbersMutation;
    usePreCheckByWarehouseMutation: typeof usePreCheckByWarehouseMutation;
    useSubmitFullOrderByWarehouseMutation: typeof useSubmitFullOrderByWarehouseMutation;
    useSubmitReducedOrderMutation: typeof useSubmitReducedOrderMutation;
}

export interface PartRequestConfig {
    usePreCheckByPartMutation: typeof usePreCheckByPartMutation;
    useGetOrderReferenceNumberLabelsMutation: typeof useGetOrderReferenceNumberLabelsMutation;
    useGetOutboundOrderPartsMutation: typeof useGetOutboundOrderPartsMutation;
    useGetServicesMutation: typeof useGetServicesMutation;
    useGetSerialNumbersMutation: typeof useGetSerialNumbersMutation;
    useSubmitFullOrderByPartMutation: typeof useSubmitFullOrderByPartMutation;
    useSubmitReducedOrderMutation: typeof useSubmitReducedOrderMutation;
}

export interface ReplenishmentRequestConfig {
    useGetPartsMaterialsMutation: typeof useGetPartsMaterialsMutation;
    useSubmitInboundReplenishmentMutation: typeof useSubmitInboundReplenishmentMutation;
    useGetMaterialsReferenceNumberLabelsMutation: typeof useGetMaterialsReferenceNumberLabelsMutation;
    useGetServicesMutation: typeof useGetServicesMutation;
    useGetAccountWarehouseMutation: typeof useGetAccountWarehouseMutation;
}

export interface TransferByWarehouseRequestConfig {
    useGetWarehousePartsMutation: typeof useGetWarehousePartsMutation;
    useGetAccountWarehouseMutation: typeof useGetAccountWarehouseMutation;
    useGetServicesMutation: typeof useGetServicesMutation;
    useGetOrderReferenceNumberLabelsMutation: typeof useGetOrderReferenceNumberLabelsMutation;
    useGetSerialNumbersMutation: typeof useGetSerialNumbersMutation;
    usePreCheckTransferByWarehouseMutation: typeof usePreCheckTransferByWarehouseMutation;
    useSubmitFullOrderByWarehouseMutation: typeof useSubmitFullOrderByWarehouseMutation;
    useSubmitReducedOrderMutation: typeof useSubmitReducedOrderMutation;
}
