import { useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { GetMaterialsDTO } from 'APIServices/materialsInReview/MaterialsInReview.types';
import { GetOrdersDTO } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { IGetMirFiltersProps } from 'components/UI/organisms/manageInventory/materialsInReview/mirUtils';
import { IGetOrderFiltersProps } from 'components/UI/organisms/trackOrders/orderList/ordersListUrlUtils';
import { IOrdersListSearchParams } from 'components/UI/organisms/trackOrders/orderList/useCurrentParams';
import { getBasicSort } from 'utils/helpers/getBasePaginationBody';
import { FullStatusList } from 'utils/helpers/orderStatus';
import { BASIC_SEARCH_PARAMS } from 'utils/helpers/paramsHandler';
import { Sorting } from 'utils/types/general/general.types';
import { MaterialsListSearchParams } from 'utils/types/mirAndTracking/MirAndTracking.types';

type CommonProps = {
    initialValue?: string;
    removeParam: (params: string[]) => void;
    sorting: Sorting;
    withReplace?: boolean;
    isMobile?: boolean;
};

type OrderSearchProps = {
    setParams: (newParams?: IOrdersListSearchParams, replace?: boolean) => void;
    config: GetOrdersDTO;
    getData: (config: GetOrdersDTO) => void;
    getFilters: (
        data?: IGetOrderFiltersProps,
        ignoreFilterDates?: boolean,
        defaultDateRangeInDays?: number
    ) => string;
    defaultDateRangeInDays: number;
    status?: string;
};

type MirSearchProps = {
    setParams: (
        newParams?: MaterialsListSearchParams,
        replace?: boolean
    ) => void;
    config: GetMaterialsDTO;
    getData: (config: GetMaterialsDTO) => void;
    getFilters: (
        data?: IGetMirFiltersProps,
        ignoreFilterDates?: boolean,
        defaultDateRangeInDays?: number
    ) => string;
    defaultDateRangeInDays?: number;
    status?: string;
};

type SearchFilterProps = CommonProps & (OrderSearchProps | MirSearchProps);

export const useSearchFilter = ({
    initialValue = '',
    setParams,
    removeParam,
    getData,
    config,
    sorting,
    status,
    getFilters,
    defaultDateRangeInDays,
    withReplace = true,
    isMobile = false
}: SearchFilterProps) => {
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setSearchValue(initialValue);
    }, [initialValue]);

    const onChangeSearch = (value: string) => {
        setSearchValue(value);
    };

    const handleSearchAction = (value: string) => {
        setParams(
            {
                [BASIC_SEARCH_PARAMS.SORTS]: getBasicSort(sorting),
                [BASIC_SEARCH_PARAMS.SEARCH]: value,
                [BASIC_SEARCH_PARAMS.STATUS]: isMobile
                    ? FullStatusList.join('|')
                    : status,
                [BASIC_SEARCH_PARAMS.PAGE]: 1,
                [BASIC_SEARCH_PARAMS.PAGE_SIZE]: config.pageSize
            },
            withReplace
        );

        const baseSort = getBasicSort(sorting);

        const defaultSorting =
            sorting.field === 'mirLastStatusDate'
                ? baseSort
                : `${baseSort}, -mirLastStatusDate`;

        getData({
            ...config,
            sorts: defaultSorting,
            filters: getFilters(
                {
                    search: value,
                    status
                },
                true
            )
        });
    };

    const getDebouncedSearch = useDebouncedCallback((value: string) => {
        handleSearchAction(value);
    }, 1000);

    const onSearchInputClean = () => {
        setSearchValue('');
        removeParam([BASIC_SEARCH_PARAMS.SEARCH]);
        getData({
            ...config,
            filters: getFilters({ status }, false, defaultDateRangeInDays)
        });
    };

    const onChangeSearchDynamically = (value: string) => {
        setSearchValue(value);
        getDebouncedSearch.cancel();

        if (!value) {
            onSearchInputClean();
            return;
        }

        const trimmedValue = value.trim();

        if (trimmedValue === searchValue.trim()) {
            return;
        }

        if (trimmedValue.length) {
            getDebouncedSearch(trimmedValue);
            return;
        }
    };

    const onSubmitSearch = (value: string) => {
        if (!value) {
            onSearchInputClean();
        } else {
            handleSearchAction(value.trim());
        }
    };

    return {
        searchValue,
        onChangeSearch,
        onChangeSearchDynamically,
        onSearchInputClean,
        onSubmitSearch
    };
};
