import React, { useEffect } from 'react';

import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetOrderTicketsMutation } from 'APIServices/trackOrders/TrackOrders.api';
import {
    GetOrdersDTO,
    TransformedTrackOrder
} from 'APIServices/trackOrders/TrackOrdersApi.types';
import { IOrdersListProps } from 'components/UI/organisms/trackOrders/orderList/OrdersList';
import { useCurrentParams } from 'components/UI/organisms/trackOrders/orderList/useCurrentParams';
import { useOrdersListUrl } from 'components/UI/organisms/trackOrders/orderList/useOrdersListUrl';
import { useAppDispatch, useAppSelector } from 'store';
import {
    IOrderTicketsById,
    setOrderTicketsById
} from 'store/slices/trackOrders/trackOrders';
import {
    initialPerPage,
    PAGINATION_ERROR_CODES
} from 'utils/constants/pagination';
import { RoutesList } from 'utils/constants/RoutesList';
import { getBasicSort } from 'utils/helpers/getBasePaginationBody';
import {
    BASIC_SEARCH_PARAMS,
    onPageChangeWithParams,
    onTableChangeWithParams
} from 'utils/helpers/paramsHandler';
import {
    PerPageOptions,
    SorterResult,
    Sorting,
    SORTING_DIRECTION
} from 'utils/types/general/general.types';

interface OrdersListReturnType {
    items?: TransformedTrackOrder[];
    totalItemsCount?: number;
    pagesCount?: number;
    page: number;
    onPageChange: (page: number) => void;
    onTableChange: (
        newPagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TransformedTrackOrder>
    ) => void;
    sorting: Sorting;
    perPageOptions?: PerPageOptions;
    onExpandOrder: (
        orderId: TransformedTrackOrder['orderId'],
        expanded: boolean
    ) => void;
    orderTicketsById?: IOrderTicketsById;
    isNoResults: boolean;
    goToDetailsPage: (
        e: React.MouseEvent,
        record: TransformedTrackOrder,
        proNum?: string
    ) => void;
    search?: string;
    expandedOrderRows: string[];
    statusParam?: string;
}

export const useOrdersList = ({
    getOrdersList,
    isLoading,
    options
}: Omit<IOrdersListProps, 'width'>): OrdersListReturnType => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { setParams, pagination, sorting, search, filters } =
        useOrdersListUrl();

    const [getOrderTickets] = useGetOrderTicketsMutation();
    const data = options?.data?.data;

    const sortingInitialState: Sorting = {
        field: 'LatestMilestoneDate',
        direction: SORTING_DIRECTION.DESC
    };

    const [searchParams] = useSearchParams();

    const { currentParams } = useCurrentParams(
        searchParams,
        sortingInitialState
    );

    const statusParam = currentParams[BASIC_SEARCH_PARAMS.STATUS];

    const dataIsEmpty =
        !isLoading && (data?.items === null || !data?.items.length);

    const isNoResults =
        options?.data?.statusCode ===
            PAGINATION_ERROR_CODES.NO_DATA_BY_FILTER ||
        options?.data?.statusCode ===
            PAGINATION_ERROR_CODES.NO_DATA_NO_MESSAGE ||
        dataIsEmpty;

    const orderTicketsById = useAppSelector(
        ({ trackOrders: { orderTicketsById } }) => orderTicketsById
    );

    const expandedOrderRows = Object.entries(orderTicketsById)
        .filter(([_, value]) => value.expanded)
        .map(([key]) => key);

    useEffect(() => {
        if (data?.totalItemsCount === 1 && !expandedOrderRows.length) {
            onExpandOrder(data.items[0].orderId, true);
            return;
        } else {
            expandedOrderRows.forEach((row) =>
                onExpandOrder(Number(row), false)
            );
        }
    }, [data?.totalItemsCount]);

    const ordersConfig: GetOrdersDTO = {
        filters: filters,
        sorts: getBasicSort(sorting),
        page: pagination.page,
        pageSize: pagination.pageSize
    };

    const onChangePerPage = (value: string) => {
        const newPagination = {
            page: 1,
            pageSize: Number(value),
            status: statusParam
        };
        setParams(newPagination);
        getOrdersList({
            ...ordersConfig,
            ...newPagination
        });
    };

    const perPageOptions =
        data?.totalItemsCount && data.totalItemsCount > initialPerPage
            ? {
                  onChangePerPage,
                  perPageValue: pagination.pageSize
              }
            : undefined;

    const onTableChange = (
        newPagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TransformedTrackOrder>
    ) => {
        onTableChangeWithParams<TransformedTrackOrder>({
            tableProps: {
                newPagination,
                filters,
                sorter: sorter
            },
            config: ordersConfig,
            sorting,
            getData: getOrdersList,
            setParams,
            status: statusParam
        });
    };

    const onPageChange = (page: number) => {
        onPageChangeWithParams({
            page,
            pageSize: pagination.pageSize,
            config: ordersConfig,
            getData: getOrdersList,
            setParams,
            status: statusParam
        });
    };

    const onExpandOrder = async (
        orderId: TransformedTrackOrder['orderId'],
        expanded: boolean
    ) => {
        const id = orderId.toString();
        dispatch(
            setOrderTicketsById({
                orderId: id,
                isLoading: true,
                data: orderTicketsById[id]?.data || [],
                expanded
            })
        );

        if (expanded) {
            const result = await getOrderTickets(id).unwrap();

            dispatch(
                setOrderTicketsById({
                    orderId: id,
                    isLoading: false,
                    data: result?.data || [],
                    expanded
                })
            );
        }
    };

    const goToDetailsPage = (
        e: React.MouseEvent,
        record: TransformedTrackOrder,
        proNum?: string
    ) => {
        const target = e.target as Element;
        const clickedOnExpandedCell = target.closest(
            '.ant-table-row-expand-icon-cell'
        );

        if (!clickedOnExpandedCell) {
            const path =
                RoutesList.TRACK_ORDERS.ORDER_DETAILS.VIEW_FULL.replace(
                    ':id',
                    String(record.orderId)
                );

            navigate(`/${path}`, {
                state: { proNum }
            });
        }
    };

    return {
        items: data?.items,
        totalItemsCount: data?.totalItemsCount,
        pagesCount: data?.pagesCount,
        page: pagination.page,
        onPageChange,
        onTableChange,
        sorting,
        perPageOptions,
        onExpandOrder,
        orderTicketsById,
        isNoResults,
        search,
        expandedOrderRows,
        goToDetailsPage,
        statusParam
    };
};
