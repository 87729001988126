import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { URLSearchParamsInit } from 'react-router-dom';

import { GetMaterialsDTO } from 'APIServices/materialsInReview/MaterialsInReview.types';
import { GetOrdersDTO } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Account } from 'APIServices/users/UsersApi.types';
import {
    getAccountsAndSubAccountsFilter,
    toUrlSearchParams
} from 'components/UI/organisms/trackOrders/orderList/ordersListUrlUtils';
import { IOrdersListSearchParams } from 'components/UI/organisms/trackOrders/orderList/useCurrentParams';
import { getBasicSort } from 'utils/helpers/getBasePaginationBody';
import {
    SorterResult,
    Sorting,
    SORTING_DIRECTION
} from 'utils/types/general/general.types';
import { MaterialsListSearchParams } from 'utils/types/mirAndTracking/MirAndTracking.types';

type NewParamsType =
    | MaterialsListSearchParams
    | IOrdersListSearchParams
    | undefined;

interface Props<T> {
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?: {
            replace?: boolean | undefined;
        }
    ) => void;
    currentParams: T;
    toUrlSearchParams: (
        data: NewParamsType
    ) => Partial<Record<keyof NewParamsType, string>>;
}

interface InitialProps {
    config: GetMaterialsDTO | GetOrdersDTO;
    getData: (dto: GetMaterialsDTO | GetOrdersDTO) => void;
    setParams: (newParams?: NewParamsType, replace?: boolean) => void;
    removeParam: (params: string[]) => void;
    secondSortField?: string | undefined;
}

export enum BASIC_SEARCH_PARAMS {
    SORTS = 'sorts',
    PAGE = 'page',
    PAGE_SIZE = 'pageSize',
    SEARCH = 'search',
    STATUS = 'status',
    ACCOUNTS_AND_SUBS = 'accountsAndSubs',
    TRANSPORT_TYPES = 'transportTypes'
}

export interface InitialSearchParams {
    [BASIC_SEARCH_PARAMS.SORTS]?: string;
    [BASIC_SEARCH_PARAMS.PAGE]?: number;
    [BASIC_SEARCH_PARAMS.PAGE_SIZE]?: number;
    sortingInitialState?: Sorting;
    [BASIC_SEARCH_PARAMS.STATUS]?: string;
    [BASIC_SEARCH_PARAMS.SEARCH]?: string;
    [BASIC_SEARCH_PARAMS.ACCOUNTS_AND_SUBS]?: string;
}

interface TableChangeProps<T extends Record<'id', string>>
    extends Omit<InitialProps, 'removeParam'> {
    tableProps: {
        newPagination: TablePaginationConfig;
        filters: Record<string, FilterValue | null>;
        sorter: SorterResult<T>;
    };
    sorting: Sorting;
    status?: string;
}

interface ChangePerPageProps extends Omit<InitialProps, 'removeParam'> {
    page: number;
    pageSize: number;
    status?: string;
}

interface AccountChangeWithParamsProps extends InitialProps {
    data: string[];
    allAccounts?: Account[];
    filter: string;
    status?: string;
}

export const useParamsHelper = <T extends InitialSearchParams>({
    setSearchParams,
    currentParams,
    toUrlSearchParams
}: Props<T>) => {
    const removeParam = (params: string[]) => {
        const withoutParam = Object.fromEntries(
            Object.entries(currentParams).filter(
                ([paramKey]) => !params.includes(paramKey)
            )
        );

        setSearchParams(toUrlSearchParams(withoutParam));
    };

    const setParams = <T>(newParams?: T, replace = false) => {
        const formattedParams: Partial<Record<string, string>> = replace
            ? toUrlSearchParams(newParams)
            : {
                  ...toUrlSearchParams(currentParams),
                  ...toUrlSearchParams(newParams)
              };

        for (const key in formattedParams) {
            if (!formattedParams[key]) {
                delete formattedParams[key];
            }
        }

        return setSearchParams(formattedParams as URLSearchParamsInit);
    };

    return { removeParam, setParams };
};

const defaultSortingConfig = {
    field: '',
    direction: SORTING_DIRECTION.DESC
};

export const toInitialUrlSearchParamsHelper = (
    result: ReturnType<typeof toUrlSearchParams> = {},
    initialParams: InitialSearchParams
) => {
    const {
        sorts,
        sortingInitialState = defaultSortingConfig,
        page,
        pageSize,
        search,
        status,
        accountsAndSubs
    } = initialParams;
    if (
        typeof sorts !== 'undefined' &&
        sorts !== getBasicSort(sortingInitialState ?? defaultSortingConfig)
    ) {
        result.sorts = sorts ?? '';
    }

    if (page) {
        result.page = page.toString();
    }

    if (pageSize) {
        result.pageSize = pageSize.toString();
    }

    if (search) {
        result.search = search;
    }

    if (accountsAndSubs) {
        result.accountsAndSubs = accountsAndSubs;
    }

    result.status = status;
};

export const onTableChangeWithParams = <T extends Record<'id', string>>({
    tableProps,
    sorting,
    getData,
    config,
    setParams,
    secondSortField,
    status
}: TableChangeProps<T>) => {
    const { sorter } = tableProps;

    if (!Array.isArray(sorter)) {
        const sortingOptions = sorter.column
            ? {
                  field: `${sorter.field}`,
                  direction: sorter.order as SORTING_DIRECTION
              }
            : sorting;

        const newPagination = {
            page: 1
        };
        const newSorting = getBasicSort(sortingOptions);

        const paramsConfig = {
            sorts: newSorting,
            ...newPagination
        };

        setParams(status ? { ...paramsConfig, status } : paramsConfig);

        getData({
            ...config,
            sorts: secondSortField
                ? `${newSorting}, ${secondSortField}`
                : newSorting,
            ...newPagination
        });
    }
};

export const onPageChangeWithParams = ({
    page,
    setParams,
    config,
    getData,
    pageSize,
    status
}: ChangePerPageProps) => {
    getData({
        ...config,
        page,
        pageSize
    });
    setParams(status ? { page, status } : { page });
};

export const onAccountChangeWithParams = ({
    data,
    removeParam,
    setParams,
    allAccounts,
    config,
    getData,
    filter,
    status
}: AccountChangeWithParamsProps) => {
    if (!data?.length) {
        removeParam([
            BASIC_SEARCH_PARAMS.ACCOUNTS_AND_SUBS,
            BASIC_SEARCH_PARAMS.PAGE
        ]);
    } else {
        const config = {
            [BASIC_SEARCH_PARAMS.ACCOUNTS_AND_SUBS]:
                getAccountsAndSubAccountsFilter(data, allAccounts),
            page: 1
        };
        setParams(status ? { ...config, status } : config);
    }

    getData({
        ...config,
        filters: filter,
        page: 1
    });
};
